//
// type.scss
//


@use "sass:color";
@use "sass:math";

// Colors

a.text-white-60:hover {
  color: fade-out($white, .2) !important;
}


// Underline

[class*="text-underline-"] {
  background: repeat-x left 1em / 20em .15em;
  // background: repeat-x no-repeat;
}

@each $color, $value in $theme-colors {
  .text-underline-#{$color} {
    @if $color=="warning" {
      background-image: linear-gradient(to left, fade-in($value, .001), fade-out($value, .0001));
    }

    @else {
      background-image: linear-gradient(to left, fade-out($value, .3), fade-out($value, .1));
    }
  }
}


// Aa text (demo only)

#text-aa-serif,
#text-aa-sans-serif {
  transition: $transition-base;
}

#text-aa-serif {
  color: var(--#{$prefix}white);
}

#text-aa-sans-serif {
  color: rgba($white, .2);
}
