//
// icon.scss
//

.icon {
  --#{$prefix}icon-size-base: #{$icon-size-base};
  --#{$prefix}icon-size-sm: #{$icon-size-sm};
  --#{$prefix}icon-size-lg: #{$icon-size-lg};
}

.icon > svg {
  width: var(--#{$prefix}icon-size-base);
  height: var(--#{$prefix}icon-size-base);
}

// Use "text-" utilities to fill svg icons
.icon > svg [fill]:not([fill="none"]) {
  fill: currentColor !important;
}


// Sizing

.icon-lg > svg {
  width: var(--#{$prefix}icon-size-lg);
  height: var(--#{$prefix}icon-size-lg);
}

.icon-sm > svg {
  width: var(--#{$prefix}icon-size-sm);
  height: var(--#{$prefix}icon-size-sm);
}

.icon-auto > svg {
  width: 1em;
  height: 1em;
}
