//
// navbar.scss
//

@use "sass:color";
@use "sass:math";

.navbar {
  transition: $transition-base;
  transition-property: box-shadow;
}

.navbar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  @include border-multicolor();
}


// Brand

.navbar-brand {
  font-family: $font-family-serif;
}


// Toggler

.navbar-toggler {
  padding-left: 0;
  padding-right: 0;
}


// Dropright

.navbar-nav .dropend > .dropdown-toggle {
  display: flex;
}

.navbar-nav .dropend > .dropdown-toggle::after {
  margin-left: auto;
  font-family: 'Feather';
  color: var(--#{$prefix}gray-500);
  content: "\e930";
}


// Dropdown menu

.navbar-nav .dropdown-positioner {
  position: absolute;
  z-index: $zindex-dropdown;
}

.navbar-nav .dropdown-positioner > .dropdown-menu {
  position: static;
}


// Mobile

@include media-breakpoint-down(md) {

  // Fixed

  .navbar.fixed-top {
    max-height: 100vh;
    overflow: auto;
  }

  // Nav

  .navbar-nav:first-child {
    margin-top: var(--#{$prefix}navbar-padding-y);
  }

  .navbar-nav > .nav-item {
    border-top: var(--#{$prefix}border-width) solid rgba($black, .065);
  }

  .navbar-nav > .nav-item > .nav-link {
    display: flex;
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  // Dropdown

  .navbar-nav > .dropdown > .dropdown-toggle::after {
    margin-left: auto;
    font-family: 'Feather';
    color: var(--#{$prefix}white);
    content: "\e92e";
    transform-origin: center center;
  }

  .navbar-nav > .dropdown.show > .dropdown-toggle::after {
    transform: rotate(180deg);
  }

  .navbar-nav > .dropdown .dropdown-menu,
  .navbar-nav > .dropdown .dropdown-menu-col {
    min-width: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .navbar-nav > .dropdown .dropdown-menu {
    padding: .75rem 0 1.5rem;
  }

  .navbar-nav > .dropdown .dropdown-menu-col {
    background-color: transparent !important;
  }

  .navbar-nav > .dropdown .dropdown-menu-col .text-white {
    color: var(--#{$prefix}body-color) !important;
  }

  .navbar-nav > .dropdown .dropdown-menu-body {
    padding: 0;
  }


  // Dropright

  .navbar-nav .dropend > .dropdown-toggle::after {
    content: "\e92e";
    transform-origin: center center;
  }

  .navbar-nav .dropend.show > .dropdown-toggle::after {
    transform: rotate(180deg);
  }

  .navbar-nav .dropend .dropdown-menu {
    padding: .75rem 0 0 .75rem;
  }

  // Dropdown menu

  .navbar-nav .dropdown-positioner {
    position: relative !important;
    transform: none !important;
  }

  // Text

  .navbar-nav .text-gray-500 {
    color: $text-muted !important;
  }
}


// Desktop

@include media-breakpoint-up(lg) {

  // Dropright

  .navbar-nav .dropend {
    width: auto;
    margin-left: -$dropdown-padding-x;
    margin-right: -$dropdown-padding-x;
    padding-left: var(--#{$prefix}dropdown-padding-x);
    padding-right: var(--#{$prefix}dropdown-padding-x);
  }

  // Toggle on hover

  .navbar-nav .dropdown-menu {
    display: none;
    opacity: 0;
    transform: translateY(10px);
    transition: $transition-base;
    transition-property: opacity, transform, -webkit-transform;
  }

  .navbar-nav .dropdown-menu.showing {
    display: block;
  }

  .navbar-nav .dropdown-menu.show {
    display: block;
    opacity: 1;
    transform: translateY(0);
  }

}


// Reveal

.navbar-reveal {
  transition: $transition-base;
}
