//
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

@use "sass:color";
@use "sass:math";
@use "sass:map";

// Font imports
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,500,700|DM+Serif+Display&display=swap");

// Bootstrap prefix variable
$prefix: bs- !default;

// Colors

$primary-dark: #800000;
$primary: #e00000;
$primary-light: #f00000;
$secondary: #a00000;
$success: #2cd4a2;
$info: #4095a0;
$warning: #f04d2a;
$danger: #f00000;
$light: #f2f2f2;
$dark: #4b0000;
$panel: rgba(0, 0, 0, 0.2);
$panel-white: rgba(255, 255, 255, 0.7);
$panel-warning: rgba(240, 77, 42, 0.85);
$panel-dark: rgba(160, 0, 0, 0.5);
$body-bg: #e00000;
$black: rgb(0, 0, 0);
$transparent: transparent;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "primary-dark": $primary-dark,
  // NEW
  "primary-light": $primary-light // NEW,
);

@import "theme/variables";

// Links
//
// Style anchor elements.

$link-color: $primary-dark;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 4%) !default;
$link-hover-decoration: none !default;

// Borders
//
// Define common padding and border radius sizes and more.

$border-width: 1px;

$border-widths: (
  0: 0,
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
  6: 6px,
  7: 7px,
);

$border-color: var(--#{$prefix}--bs-secondary);

$border-radius: 1rem;
$border-radius-lg: 2rem;
$border-radius-sm: 0.5rem;
$border-radius-pill: 50rem;
$border-radius-none: 0rem;

$box-shadow: 0 0 6px color.adjust($black, $alpha: -0.97),
  0 14px 24px color.adjust($black, $alpha: -0.94);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow-lg: 0 0 24px color.adjust($black, $alpha: -0.96),
  0 44px 74px color.adjust($black, $alpha: -0.94);
$box-shadow-lift: 0 1rem 2.5rem color.adjust($black, $alpha: -0.9),
  0 0.5rem 1rem -0.75rem color.adjust($black, $alpha: -0.9);
$box-shadow-lift-lg: 0 1rem 5rem color.adjust($black, $alpha: -0.9),
  0 2rem 3rem -1rem color.adjust($black, $alpha: -0.95);
$box-shadow-lift-light: 0 1rem 2.5rem color.adjust($white, $alpha: -0.9),
0 0.5rem 1rem -0.75rem color.adjust($black, $alpha: -0.9);
$box-shadow-lift-lg-light: 0 1rem 5rem color.adjust($white, $alpha: -0.9),
0 2rem 3rem -1rem color.adjust($black, $alpha: -0.95);

// Flickity

$flickity-button-size: 3rem;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$h1-font-size: 2.25rem !default; // 36px
$h2-font-size: 1.75rem !default; // 28px
$h3-font-size: 1.25rem !default; // 20px
$h4-font-size: 1.125rem !default; // 18px
$h5-font-size: 1rem !default; // 16px
$h6-font-size: 0.875rem !default; // 14px
$font-family-sans-serif: "Source Sans Pro", sans-serif;

//Navs

$nav-tabs-border-color: transparent !default;
$nav-tabs-border-width: calc(var(--#{$prefix}border-width) * 3) !default;
$nav-tabs-border-radius: 0 !default;
$nav-tabs-link-active-bg: transparent !default;
$nav-tabs-link-active-border-color: transparent transparent
  var(--#{$prefix}warning) transparent !default;

$nav-tabs-link-padding-x: 0 !default;
$nav-tabs-link-padding-bottom: 1.3125rem !default;

$nav-tabs-item-spacer: 2rem !default;

$nav-pills-border-radius: var(--#{$prefix}border-radius-sm) !default;

$nav-link-color-dark: var(--#{$prefix}secondary);
$nav-link-hover-color-dark: var(--#{$prefix}dark);
$nav-link-active-color-dark: var(--#{$prefix}white);

// Navbar

$navbar-padding-y: 1rem;

$navbar-nav-link-padding-x: 2rem;

$navbar-brand-font-size: $h4-font-size;

$navbar-dark-color: var(--#{$prefix}secondary);
$navbar-dark-hover-color: var(--#{$prefix}primary);
$navbar-dark-active-color: var(--#{$prefix}secondary);
$navbar-dark-disabled-color: var(--#{$prefix}panel);
$navbar-dark-toggler-border-color: var(--#{$prefix}transparent);

$navbar-light-color: var(--#{$prefix}primary);
$navbar-light-hover-color: var(--#{$prefix}secondary);
$navbar-light-active-color: var(--#{$prefix}primary);
$navbar-light-disabled-color: var(--#{$prefix}panel);
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$white}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");

$navbar-light-toggler-border-color: transparent;

$navbar-dark-brand-color: var(--#{$prefix}white);
$navbar-dark-brand-hover-color: var(--#{$prefix}white);
$navbar-light-brand-color: var(--#{$prefix}dark);
$navbar-light-brand-hover-color: var(--#{$prefix}dark);

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 10rem !default;
$dropdown-min-width-lg: 20rem !default;
$dropdown-min-width-xl: 40rem !default;

$dropdown-padding-y: .75rem;
$dropdown-padding-x: var(--#{$prefix}dropdown-padding-y);
$dropdown-font-size: $font-size-sm;
$dropdown-color: var(--#{$prefix}primary-light);
$dropdown-bg: var(--#{$prefix}body-bg);
$dropdown-border-color: var(--#{$prefix}border-color-translucent);
$dropdown-border-width: var(--#{$prefix}border-width);
$dropdown-border-radius: (var(--#{$prefix}border-radius)) 0 (var(--#{$prefix}border-radius)) 0;

//Crazy workaround for top-left and bottom-right border radii::after
// Define separate variables for each corner's radius
$dropdown-border-radius-top-left: var(--#{$prefix}border-radius);
$dropdown-border-radius-top-right: 0;
$dropdown-border-radius-bottom-right: var(--#{$prefix}border-radius);
$dropdown-border-radius-bottom-left: 0;

// Calculate inner border radius for each corner
$dropdown-inner-border-radius-top-left: calc(#{$dropdown-border-radius-top-left} - #{$dropdown-border-width});
$dropdown-inner-border-radius-top-right: 0;
$dropdown-inner-border-radius-bottom-right: calc(#{$dropdown-border-radius-bottom-right} - #{$dropdown-border-width});
$dropdown-inner-border-radius-bottom-left: 0;

// Combine the inner border radius values
$dropdown-inner-border-radius: #{$dropdown-inner-border-radius-top-left} #{$dropdown-inner-border-radius-top-right} #{$dropdown-inner-border-radius-bottom-right} #{$dropdown-inner-border-radius-bottom-left};
$dropdown-divider-margin-y: $spacer * 0.5 !default;
$dropdown-box-shadow: var(--#{$prefix}box-shadow) !default;

$dropdown-link-color: var(--#{$prefix}body-color);
$dropdown-link-hover-color: $dropdown-link-color;
$dropdown-link-hover-bg: var(--#{$prefix}tertiary-bg);

$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg: $component-active-bg !default;

$dropdown-link-disabled-color: var(--#{$prefix}tertiary-color) !default;

$dropdown-item-padding-y: $spacer * 0.25;
$dropdown-item-padding-x: $spacer * 0.5;

$dropdown-header-color: $gray-600 !default;
$dropdown-header-padding-x: $dropdown-item-padding-x !default;
$dropdown-header-padding-y: $dropdown-padding-y !default;
// fusv-disable
$dropdown-header-padding: $dropdown-header-padding-y $dropdown-header-padding-x !default; // Deprecated in v5.2.0
// fusv-enable

// Forms

$input-focus-border-color: none;

// Cards

$card-stack-offset: 120px;

// Tooltips

$tooltip-font-size: $font-size-xs;
$tooltip-max-width: 280px;
$tooltip-bg: var(--#{$prefix}panel-warning);
$tooltip-border-radius: .375rem;
$tooltip-opacity: 1;
$tooltip-padding-y: .25rem;
$tooltip-padding-x: .25rem;
$tooltip-color: var(--#{$prefix}primary-dark);

// Utilities

$bg-checkered-size: 32px !default;
$bg-checkered-color: var(--#{$prefix}white) !default;

$utilities: (
  "background-color": (
    property: background-color,
    class: bg,
    values:
      map-merge(
        $theme-colors,
        (
          "body": var(--#{$prefix}body-bg),
          "white": var(--#{$prefix}white),
          "transparent": transparent,
          "dark-50": rgba($black, 0.5),
          "gray-900": var(--#{$prefix}gray-900),
          "gray-800": var(--#{$prefix}gray-800),
          "gray-300": var(--#{$prefix}gray-300),
          "gray-200": var(--#{$prefix}gray-200),
          "white-10": rgba($white, 0.1),
          "white-20": rgba($white, 0.2),
        )
      ),
  ),
  "border": (
    responsive: true,
    property: border,
    values: (
      null: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color),
      0: 0,
    ),
  ),
  "border-top": (
    responsive: true,
    property: border-top,
    values: (
      null: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color),
      0: 0,
      1: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color),
      2: calc(var(--#{$prefix}border-width) * 2) solid
        var(--#{$prefix}border-color),
      3: calc(var(--#{$prefix}border-width) * 3) solid
        var(--#{$prefix}border-color),
      4: calc(var(--#{$prefix}border-width) * 4) solid
        var(--#{$prefix}border-color),
      5: calc(var(--#{$prefix}border-width) * 5) solid
        var(--#{$prefix}border-color),
      6: calc(var(--#{$prefix}border-width) * 6) solid
        var(--#{$prefix}border-color),
      7: calc(var(--#{$prefix}border-width) * 7) solid
        var(--#{$prefix}border-color),
    ),
  ),
  "border-end": (
    responsive: true,
    property: border-right,
    class: border-end,
    values: (
      null: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color),
      0: 0,
      1: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color),
      2: calc(var(--#{$prefix}border-width) * 2) solid
        var(--#{$prefix}border-color),
      3: calc(var(--#{$prefix}border-width) * 3) solid
        var(--#{$prefix}border-color),
      4: calc(var(--#{$prefix}border-width) * 4) solid
        var(--#{$prefix}border-color),
      5: calc(var(--#{$prefix}border-width) * 5) solid
        var(--#{$prefix}border-color),
      6: calc(var(--#{$prefix}border-width) * 6) solid
        var(--#{$prefix}border-color),
      7: calc(var(--#{$prefix}border-width) * 7) solid
        var(--#{$prefix}border-color),
    ),
  ),
  "border-bottom": (
    responsive: true,
    property: border-bottom,
    values: (
      null: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color),
      0: 0,
      1: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color),
      2: calc(var(--#{$prefix}border-width) * 2) solid
        var(--#{$prefix}border-color),
      3: calc(var(--#{$prefix}border-width) * 3) solid
        var(--#{$prefix}border-color),
      4: calc(var(--#{$prefix}border-width) * 4) solid
        var(--#{$prefix}border-color),
      5: calc(var(--#{$prefix}border-width) * 5) solid
        var(--#{$prefix}border-color),
      6: calc(var(--#{$prefix}border-width) * 6) solid
        var(--#{$prefix}border-color),
      7: calc(var(--#{$prefix}border-width) * 7) solid
        var(--#{$prefix}border-color),
    ),
  ),
  "border-start": (
    responsive: true,
    property: border-left,
    class: border-start,
    values: (
      null: var(--#{$prefix}border-width) solid var(--#{$prefix}border-color),
      0: 0,
    ),
  ),
  "border-color": (
    property: border-color,
    class: border,
    values:
      map-merge(
        map-merge(
          $theme-colors,
          (
            "white": var(--#{$prefix}white),
            "transparent": transparent,
            "white-5": color.adjust($white, $alpha: -0.95),
            "white-10": color.adjust($white, $alpha: -0.9),
            "white-20": color.adjust($white, $alpha: -0.8),
            "gray-300": var(--#{$prefix}gray-300),
            "gray-900": var(--#{$prefix}gray-900),
          )
        ),
        $brand-colors
      ),
  ),
  "border-width": (
    responsive: true,
    property: border-width,
    class: border,
    values: $border-widths,
  ),
  "rounded": (
    property: border-radius,
    class: rounded,
    values: (
      null: var(--#{$prefix}border-radius),
      0: 0,
      1: var(--#{$prefix}border-radius-sm),
      2: var(--#{$prefix}border-radius),
      3: var(--#{$prefix}border-radius-lg),
      circle: 50%,
      pill: var(--#{$prefix}border-radius-pill),
      inherit: "inherit",
    ),
  ),
  "rounded-top-end": (
    property: border-top-right-radius,
    class: rounded-top-end,
    values: (
      null: var(--#{$prefix}border-radius),
      0: 0,
      1: var(--#{$prefix}border-radius-sm),
      2: var(--#{$prefix}border-radius),
      3: var(--#{$prefix}border-radius-lg),
    ),
  ),
  "rounded-bottom-end": (
    property: border-bottom-right-radius,
    class: rounded-bottom-end,
    values: (
      null: var(--#{$prefix}border-radius),
      0: 0,
      1: var(--#{$prefix}border-radius-sm),
      2: var(--#{$prefix}border-radius),
      3: var(--#{$prefix}border-radius-lg),
    ),
  ),
  "rounded-top-start": (
    property: border-top-left-radius,
    class: rounded-top-start,
    values: (
      null: var(--#{$prefix}border-radius),
      0: 0,
      1: var(--#{$prefix}border-radius-sm),
      2: var(--#{$prefix}border-radius),
      3: var(--#{$prefix}border-radius-lg),
    ),
  ),
  "rounded-bottom-start": (
    property: border-bottom-left-radius,
    class: rounded-bottom-start,
    values: (
      null: var(--#{$prefix}border-radius),
      0: 0,
      1: var(--#{$prefix}border-radius-sm),
      2: var(--#{$prefix}border-radius),
      3: var(--#{$prefix}border-radius-lg),
    ),
  ),
  "shadow": (
    property: box-shadow,
    class: shadow,
    values: (
      null: $box-shadow,
      sm: $box-shadow-sm,
      lg: $box-shadow-lg,
      none: none,
      img: 2rem 2rem var(--#{$prefix}gray-200),
    ),
  ),
  "width": (
    responsive: true,
    property: width,
    class: w,
    values: (
      25: 25%,
      50: 50%,
      75: 75%,
      100: 100%,
      125: 125%,
      150: 150%,
      auto: auto,
    ),
  ),
  "height": (
    responsive: true,
    property: height,
    class: h,
    values: (
      25: 25%,
      50: 50%,
      75: 75%,
      100: 100%,
      125: 125%,
      150: 150%,
      auto: auto,
    ),
  ),
  "max-width": (
    responsive: true,
    property: max-width,
    class: mw,
    values: (
      25: 25%,
      50: 50%,
      75: 75%,
      100: 100%,
      125: 125%,
      150: 150%,
    ),
  ),
  "viewport-width": (
    responsive: true,
    property: width,
    class: vw,
    values: (
      25: 25vw,
      50: 50vw,
      75: 75vw,
      100: 100vw,
      125: 125vw,
      150: 150vw,
    ),
  ),
  "viewport-height": (
    responsive: true,
    property: height,
    class: vh,
    values: (
      25: 25vh,
      50: 50vh,
      75: 75vh,
      100: 100vh,
      125: 125vh,
      150: 150vh,
    ),
  ),
  "max-viewport-width": (
    property: max-width,
    class: max-vw,
    values: (
      100: 100vw,
    ),
  ),
  "font-family": (
    property: font-family,
    class: font,
    values: (
      monospace: var(--#{$variable-prefix}font-monospace),
      sans-serif: $font-family-sans-serif,
      serif: $font-family-serif,
    ),
  ),
  "color": (
    property: color,
    class: text,
    values:
      map-merge(
        map-merge(
          $theme-colors,
          (
            "white": var(--#{$prefix}white),
            "body": var(--#{$prefix}body-color),
            "muted": $text-muted,
            "black-50": rgba($black, 0.5),
            "white-50": rgba($white, 0.5),
            "reset": inherit,
            "white-10": color.adjust($white, $alpha: -0.9),
            "white-20": color.adjust($white, $alpha: -0.8),
            "white-60": color.adjust($white, $alpha: -0.4),
            "white-80": color.adjust($white, $alpha: -0.2),
            "gray-300": var(--#{$prefix}gray-300),
            "gray-500": var(--#{$prefix}gray-500),
            "gray-600": var(--#{$prefix}gray-600),
            "gray-800": var(--#{$prefix}gray-800),
            "gray-900": var(--#{$prefix}gray-900),
          )
        ),
        $brand-colors
      ),
  ),
);

:root,
[data-bs-theme="light"] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ff5430;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 75, 0, 0;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #e00000;
  --bs-body-color-rgb: 224, 0, 0;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(255, 84, 45, 0.5);
  --bs-tertiary-color-rgb: 255, 84, 45;
  --bs-tertiary-bg: var(--bs-warning);
  --bs-tertiary-bg-rgb: 255, 84, 45;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.1);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
  --bs-panel: rgba(0, 0, 0, 0.2);
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #4b0000;
  --bs-body-bg-rgb: (75, 0, 0);
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(255, 84, 45, 0.5);
  --bs-tertiary-color-rgb: 255, 84, 45;
  --bs-tertiary-bg: var(--bs-warning);
  --bs-tertiary-bg-rgb: 255, 84, 45;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
  --bs-panel: rgba(0, 0, 0, 0.2);
}
