//
// collapse.scss
//

// Toggle

.collapse-toggle {
  display: flex;
}

.collapse-toggle::after {
  content: "\e9bc";
  align-self: flex-start;
  margin-left: auto;
  font-family: "Feather";
  color: var(--#{$prefix}warning);
  transform-origin: center center;
  transition: $transition-base;
}

.collapse-toggle[aria-expanded="true"]::after {
  transform: rotate(45deg);
}
