//
// toasts.scss
//

.toast {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}


// Header

.toast-header {
  padding-top: $toast-header-padding-y;
  padding-bottom: $toast-header-padding-y;
  border-bottom-width: var(--#{$prefix}border-width);
}


// Positioner

.toast-positioner {
  position: fixed;
  z-index: $zindex-fixed;
  bottom: $toast-positioner-spacing;
  width: 100%;
  max-width: var(--#{$prefix}toast-max-width);
}

.toast-positioner-start {
  left: $toast-positioner-spacing;
}

.toast-positioner-end {
  right: $toast-positioner-spacing;
}
