//
// borders.scss
//

@use "sass:color";
@use "sass:math";
@use "sass:map";

// Colors

.border-multicolor {
  @include border-multicolor();
}