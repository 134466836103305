//
// buttons.scss
//

@use "sass:color";
@use "sass:math";

.btn:not([class*="btn-outline"]) {
  border-color: transparent !important;
}


// Hovers

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    &:hover, &:focus {
      background-color: darken($value, 4%);
    }
  }
}

// Colors

.btn-white {
  @include button-variant($white, $white);
  color: var(--#{$prefix}link-color);

  &:hover, &:focus, &:active {
    background-color: var(--#{$prefix}white);
    color: var(--#{$prefix}link-hover-color);
  }
}

.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active {
  background-color: var(--#{$prefix}white);
  color: var(--#{$prefix}link-hover-color);
}

.btn-gray-200 {
  @include button-variant($gray-200, $gray-200);
  color: var(--#{$prefix}link-hover-color);

  &:hover, &:focus, &:active {
    color: var(--#{$prefix}link-hover-color);
  }
}

.btn-gray-200:not(:disabled):not(.disabled):active,
.btn-gray-200:not(:disabled):not(.disabled).active {
  color: var(--#{$prefix}link-hover-color);
}

.btn-gray-300 {
  @include button-variant($gray-300, $gray-300);
  color: var(--#{$prefix}link-hover-color);

  &:hover, &:focus, &:active {
    color: var(--#{$prefix}link-hover-color);
  }
}

.btn-gray-300:not(:disabled):not(.disabled):active,
.btn-gray-300:not(:disabled):not(.disabled).active {
  color: var(--#{$prefix}link-hover-color);
}


// Circle

.btn-circle {
  padding-left: var(--#{$prefix}btn-padding-y);
  padding-right: var(--#{$prefix}btn-padding-y);
  border-radius: 50%;
}

.btn-circle > * {
  display: block;
  width: calc(#{var(--#{$prefix}btn-font-size)} * #{var(--#{$prefix}btn-line-height)});
  line-height: inherit;
}

// Sizing

.btn-circle.btn-lg {
  padding-left: $btn-padding-y-lg;
  padding-right: $btn-padding-y-lg;
  border-radius: 50%;
}

.btn-circle.btn-lg > * {
  width: calc(#{$btn-font-size-lg} * #{$btn-line-height-lg});
}

.btn-circle.btn-sm {
  padding-left: $btn-padding-y-sm;
  padding-right: $btn-padding-y-sm;
  border-radius: 50%;
}

.btn-circle.btn-sm > * {
  width: calc(#{$btn-font-size-sm} * #{$btn-line-height-sm});
}


// Link

.btn-link {
  font-weight: var(--#{$prefix}btn-font-weight);
}
