//
// user.scss
// Use this to write your custom SCSS

@use "sass:color";
@use "sass:math";

html,
body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
}

// Button reset
button {
  -webkit-appearance: button;
  appearance: none;
}

// Sans serif

.display-1.font-sans-serif,
.display-2.font-sans-serif,
.display-3.font-sans-serif,
.display-4.font-sans-serif {
  letter-spacing: -0.02em;
  font-weight: 700;
}

h1.font-sans-serif,
.h1.font-sans-serif {
  letter-spacing: -0.02em;
}

h2.font-sans-serif,
.h2.font-sans-serif,
h3.font-sans-serif,
.h3.font-sans-serif,
h4.font-sans-serif,
.h4.font-sans-serif {
  letter-spacing: -0.01em;
}

.stickyWidget {
  flex: 1 0 auto;
}
.stickyWidget {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 3.5rem;
  background-color: rgba(128, 0, 0, 0.9);
  color: #fff; /* Replace with your desired text color */
  text-align: text-center;
  line-height: 20px;
  display: flex;
  align-items: center; /* Center the content vertically */
  justify-content: center;
  z-index: 999;
}
.bg-transparent {
  background-color: transparent;
}
.bg-panel {
  background-color: rgba(245, 75, 41, 0.3);
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a00000;
  opacity: .90; /* Firefox */
  font-size: medium !important;
  font-style: italic;
}

.form-control::focus {
  outline: none !important;
  box-shadow: none !important;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a00000;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a00000;
}

.object-fit-cover {
  object-fit: cover;
  width: 100%;
  height: 110%;
}

.object-fit-cover {
  object-fit: cover;
  width: 100%;
  height: 110%;
}

/* Style the input container */
.form-control {
  width: calc(
    100% - 30px
  ); /* Adjust the width to account for the logo and margin */
}
.input-container {
  max-width: 450px; /* Set the maximum width to match just the input box */
  display: flex;
  align-items: center;
}

/* Search Results Component */
#searchResultsContainer {
  max-width: 97%;
  margin: 1px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: none;
  background-color: #f54b29;
  z-index: 1;
  scrollbar-width: thin;
  scrollbar-color: #be361b transparent;
}

#searchResultsContainer a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #a00000;
  border: 0.5px solid #be361b !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  font-size: 0.85rem;
  font-weight: bold;
  margin: 0;
}

#searchResultsContainer a:hover {
  background-color: #be361b;
}

#searchResultsContainer::-webkit-scrollbar {
  width: 10px;
}

#searchResultsContainer::-webkit-scrollbar-track {
  background: transparent;
}

#searchResultsContainer::-webkit-scrollbar-thumb {
  background: #be361b;
  border-radius: 5px;
}

.search-results-item {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #a00000;
  border: 0.5px solid #be361b !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  font-size: 0.8rem;
  font-weight: bold;
  margin: 0;
}

.blur-effect {
  filter: blur(5px);
  transition: filter 0.3s ease-in-out;
}

#searchLogo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: auto;
  z-index: 10;
}

/* Style the logo container */
.logo-container {
  width: 20px; /* Set the desired width for the logo */
  height: 20px; /* Set the desired height for the logo */
  margin-right: 10px; /* Adjust the margin to separate the logo from the input field */
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Define styles for the table */
.cached-data-table {
  border-collapse: collapse;
  border: 0.25px solid rgba(0, 0, 0, 0.25); /* .25px borders */
  background-color: transparent; /* Transparent background */
  font-size: 0.9rem;
  font-weight: bold;
  z-index: 10;
}

.cached-data-table th,
.cached-data-table td {
  padding: 8px;
  border: 0.25px solid rgba(0, 0, 0, 0.25); /* .25px borders for table cells */
  text-align: left;
}

/* Style the header row */
.cached-data-table thead th {
  background-color: rgba(
    0,
    0,
    0,
    0.1
  ); /* Light gray background for header row */
}
#orgCacheDisplay pre {
  max-width: 800px; /* Set your desired max-width */
  overflow: visible; /* Display overflowing content */
  white-space: pre-wrap; /* Allow text to wrap */
  font-size: xx-small;
}
#orgCacheDisplay {
  max-width: 800px; /* Set your desired max-width */
  overflow: visible; /* Display overflowing content */
  white-space: pre-wrap; /* Allow text to wrap */
  font-size: xx-small;
  height: 200px;
  overflow: auto;
}

/* ---- grid ---- */
.container-fluid {
  // background-color: red;
  width: 100%;
  padding: 0 !important;
  margin: 0 auto;
  justify-content: center;
}

.grid {
  height: auto;
  width: 100% !important;
  margin: 0 auto;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.grid-sizer,
.grid-item {
  width: 32%;
}

.grid-item {
  padding: 10px;
  box-sizing: border-box;
}

/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

/* Adjust for full width (e.g., desktop) */
@media (min-width: 1170px) {
  .grid-sizer,
  .grid-item {
    width: 32%;
  }
}

@media (min-width: 992px) and (max-width: 1169px) {
  .grid-sizer,
  .grid-item {
    width: 46%; // 2 columns
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .grid-sizer,
  .grid-item {
    width: 46%; // 2 columns
  }
}

/* Adjust for narrowest media width (e.g., mobile) */
@media (max-width: 576px) and (max-width: 767px) {
  .grid-sizer,
  .grid-item {
    width: 100%; // 1 column
  }
}

/* Extra small screens (below 576px) */
@media (max-width: 575.98px) {
  .grid-sizer,
  .grid-item {
    width: 100%; // 1 column
  }
}

.chart-container {
  width: 100%;
  position: absolute;
}

.chartTooltip {
  color: #800000;
  font-weight: 700;
  visibility: hidden;
  z-index: 1000;
  position: absolute;
  font-family: sans-serif;
  font-size: 1.2rem;
}

.chart-card {
  font-family: sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  color: #800000;
  position: relative;
  margin: 0;
  padding: 1.25rem;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  max-width: 100%;
  min-height: 400px;
  overflow: hidden;
}

.chart-title {
  color: #aaa;
  font-weight: 300;
  position: relative;
  font-family: sans-serif;
  font-size: 1.2rem;
  margin-bottom: 2px;
}

.trans-lower {
  transform: translateY(-10px);
}

.chart-text {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-weight: 200;
  font-family: sans-serif;
  color: #4b0000;
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
}

body {
  background-color: #f3f5f7;
  font-family: "Helvetica Neue", Arial, sans-serif;
}

.chart {
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 300px;
  height: 375px;
  overflow: hidden;
}

.chart .about {
  height: 225px;
  padding: 20px;
  box-sizing: border-box;
}

.chart .about h3,
.chart .about .lead {
  font-weight: 300;
  margin: 0;
}

.chart .about h3 {
  font-size: 24px;
}

.chart .about .lead {
  color: #aaa;
}

.chart .info {
  float: left;
  padding: 10px 30px 10px 0;
}

.chart .info p {
  font-size: 11px;
  color: #aaa;
  font-weight: 300;
}

.chart-item {
  // background-color: yellow;
  margin: 10px; /* Set margin around grid items */
  min-height: 378px;
  box-sizing: border-box;
}

/* Adjust for full width (e.g., desktop) */
@media (min-width: 1170px) {
  .chart-item {
    width: calc(30% - 10px); /* Adjust width for full width screens */
    margin: 8px; /* Adjust margin for full width screens */
  }
}

@media (min-width: 992px) and (max-width: 1139px) {
  .chart-item {
    width: calc(30% - 10px); /* Adjust width for middle media screens */
    margin: 8px; /* Adjust margin for middle media screens */
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .chart-item {
    width: calc(45% - 10px); /* Adjust width for middle media screens */
    margin: 10px; /* Adjust margin for middle media screens */
  }
}
/* Adjust for narrowest media width (e.g., mobile) */
@media (max-width: 576px) and (max-width: 767px) {
  .chart-item {
    width: calc(45% - 10px); /* Adjust width for narrowest screens */
    margin: 10px; /* Adjust margin for narrowest screens */
  }
}

/* Extra small screens (below 576px) */
@media (max-width: 575.98px) {
  .grid-item {
    width: calc(90% - 10px); 
    margin: 10px;
  }
}

.legends {
  padding-top: 20px;
  overflow: hidden;
}

.legend {
  display: block;
  width: 8px;
  height: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.legend--total {
  background-color: #5555ff;
}

.legend--avg {
  background-color: #ff55b8;
}

.axis {
  position: absolute;
  color: #fff;
  z-index: 1;
  text-transform: uppercase;
  display: flex;
  width: 100%;
  bottom: 0;
}

.axis .tick {
  flex: 1;
  position: relative;
  overflow: hidden;
  opacity: 0.2;
  font-size: 11px;
  text-align: center;
  line-height: 40px;
  padding-top: 150px;
}

.axis .tick:hover {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.2);
}

.axis .tick .value {
  transform: translateY(-40px);
  transition: 0.3s transform;
  position: absolute;
  top: 20px;
  color: #444;
  border-radius: 2px;
  width: 100%;
  line-height: 20px;
}

.axis .tick:hover .value.value--total {
  transform: translateY(0);
  display: block;
}

.value.value--total {
  color: #5555ff;
  font-weight: bold;
}

.iframe-container {
  position: relative;
  width: 1px;
  min-width: 100%;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Set iframe height to 100% to match its container */
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

#viewer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  transition: all 0.3s ease;
  margin-top: auto;
  transform: translateY(0);

  &.is-sticky {
    position: fixed;
    transition: all 0.8s ease;
  }

  &.is-hidden {
    transform: translateY(100%);
    transition: all 0.6s ease;
  }
}

#samples {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.btn-text-lift {
  transition: transform 0.2s;
  display: inline-block;
}

.btn:hover .btn-text-lift {
  transform: translateY(-2px);
}

.text-sm {
  font-size: 0.9rem;
}

.text-justify {
  text-align: justify;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.user-select-disabled img {
  user-select: none;
  pointer-events: none;
}

.nav-link {
  &.h6 {
    font-size: 0.875rem !important;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
  }
}



.navbar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: none;
}

// Flickity Buttons

// Center right
.flickity-buttons-center-end .flickity-button {
  top: 50%; // Center vertically
  bottom: auto;
  transform: translateY(-50%);
}

.flickity-buttons-center-end .flickity-button.next {
  right: 1.5rem; // Rightmost button
}

.flickity-buttons-center-end .flickity-button.previous {
  left: auto;
  right: calc(1rem + #{$flickity-button-size});
}

@include media-breakpoint-down(sm) {
  .flickity-buttons-center-end .flickity-button.next {
    right: 1rem;
  }

  .flickity-buttons-center-end .flickity-button.previous {
    right: calc(1rem + #{$flickity-button-size});
  }
}

.flickity-button {
  z-index: 1;
  width: $flickity-button-size;
  height: $flickity-button-size;
  border-radius: 0;
  transition: $btn-transition;
  background-color: $primary;
  border-color: $primary;
  color: $white;
  box-shadow: none !important;

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
    background-color: darken($primary, 4%);
  }
}

.flickity-dark-buttons .flickity-button {
  background-color: $secondary;
  border-color: $secondary;
  color: $white;

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
    background-color: darken($secondary, 4%);
    border-color: darken($secondary, 4%);
    color: $white;
  }

  &.next::after {
    border-left-color: fade-out($white, 0.8);
  }
}

.bg-gr-white-light {
  background-image: linear-gradient(to bottom, rgba($white, 100), $light);
}

.bg-gr-white-warning {
  background-image: linear-gradient(to bottom, rgba($white, 25), $warning);
}

.card {
  box-shadow: var(--#{$prefix}card-box-shadow);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}


.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex-grow: 1;
  flex: 1 1 auto;
  padding: var(--#{$prefix}card-spacer-y) var(--#{$prefix}card-spacer-x);
  color: var(--#{$prefix}card-color);
}

.card-img-top {
  object-fit: cover;
  width: 100%;
}

.card-fh-1 {
  height: 200px;
  overflow: auto;
}

.card-fh-2 {
  height: 260px;
  overflow: auto;
}

.card-fh-3 {
  height: 320px;
  overflow: auto;
}

.card-fh-4 {
  height: 400px;
  overflow: scroll;
}

#bio .bio {
  display: none;
}

#bio .bio.active {
  display: flex;
}

.background-container {
  position: absolute;
  top: -30px;
  left: 0px;
  width: 50%;
  height: 200%;
}

// loading.js animated logo

.loading-placeholder {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f8f9fa;
  z-index: 10;
}

.animated-logo {
  width: 100px;
  height: 100px;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
}

.fade-out {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.lift-light {
  transition: box-shadow .25s ease, transform .25s ease;
}

.lift-light:hover,
.lift-light:focus {
  box-shadow: $box-shadow-lift-light !important;
  transform: translate3d(0, -3px, 0);
}

.lift-light-lg:hover,
.lift-light-lg:focus {
  box-shadow: $box-shadow-lift-lg-light !important;
  transform: translate3d(0, -5px, 0);
}
