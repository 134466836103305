//
// badge.scss
//

@use "sass:color";
@use "sass:math";

@mixin badge-variant-soft($color, $badge-soft-bg-opacity) {
  background-color: rgba($color, $badge-soft-bg-opacity);
  color: $color;

  @at-root a#{&} {
    &:hover, &:focus {
      background-color: rgba($color, $badge-soft-bg-opacity * 2);
      color: $color;
    }
  }
}
