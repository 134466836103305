//
// pagination.scss
//

.pagination {
  --#{$prefix}pagination-border-radius-sm: #{$pagination-border-radius-sm};
}

.pagination .page-item:first-child .page-link {
  border-top-left-radius: var(--#{$prefix}pagination-border-radius);
  border-bottom-left-radius: var(--#{$prefix}pagination-border-radius);
}

.pagination .page-item:last-child .page-link {
  border-top-right-radius: var(--#{$prefix}pagination-border-radius);
  border-bottom-right-radius: var(--#{$prefix}pagination-border-radius);
}


// Sizing

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: var(--#{$prefix}pagination-border-radius-sm);
  border-bottom-left-radius: var(--#{$prefix}pagination-border-radius-sm);
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: var(--#{$prefix}pagination-border-radius-sm);
  border-bottom-right-radius: var(--#{$prefix}pagination-border-radius-sm);
}
