//
// bigpicture.scss
// Plugin overrides
//


@use "sass:color";
@use "sass:math";

#bp_container {
  background: rgba($black, .9) !important;
}

#bp_container > * {
  box-shadow: none !important;
}

.bp-x {
  display: none;
}